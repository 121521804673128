body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* For SweetAlert */
.swal2-popup.swal2-modal.swal2-show {
  border-radius: 10px;
}
.MuiDateTimePickerToolbar-ampmLabel.Mui-selected {
  color: white !important;
  background-color: rgb(79, 60, 247);
  padding: 1px 4px 1px 4px;
  border-radius: 4px;
}
.MuiDateTimePickerToolbar-ampmLabel{
  padding: 1px 4px 1px 4px;
}

.MuiBottomNavigationAction-label.Mui-selected{
  font-size: 12px !important;
}